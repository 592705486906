// import './polyfills.js'
import SliderSlickInit from './sliderSlick.js'
import PopupInit from './popupInit.js'
import FormInit from './form.js'
import MenuHover from './menuHover.js'
import LeftNav from './leftNav.js'
import CalculatorInit from './calculator.js'
import StepsInit from './steps.js'
import ResultInit from './result.js'

document.addEventListener('turbolinks:load', function () {
    PopupInit();
    SliderSlickInit();
    FormInit();
    MenuHover();
    LeftNav();
    CalculatorInit();
    StepsInit();
    ResultInit();
});
