import 'slick-carousel';

function initSliderMain() {
    const $sliderTop = $('.slider-top');
    if (!$sliderTop.length) {
        return
    }
    $sliderTop.slick({
        infinite: true,
        autoplay: false,
        autoplaySpeed: 5000,
        arrows: false,
        fade: true,
        dots: true,
        appendDots: $('.slider-top-dots')
    });

    document.addEventListener('turbolinks:before-cache', () => $sliderTop.slick('unslick'))
}

function initSliderArticles() {
    const $slidesChannels = $('.articles__slides');
    if (!$slidesChannels.length) {
        return;
    }

    $slidesChannels.slick({
        centerMode: false,
        slidesToShow: 3,
        centerPadding: '0px',
        swipeToSlide: true,
        arrows: false,
        dots: true,
        infinite: false,
        speed: 500,
        appendDots: $('.article__slides-dots'),
    });
    document.addEventListener('turbolinks:before-cache', () => $slidesChannels.slick('unslick'))
}

function initSliderTrust() {
    const $slidesChannels = $('.trust__slides');
    if (!$slidesChannels.length) {
        return;
    }

    $slidesChannels.slick({
        centerMode: false,
        slidesToShow: 6,
        centerPadding: '0px',
        swipeToSlide: true,
        arrows: false,
        dots: true,
        infinite: false,
        speed: 500,
        appendDots: $('.trust__slides-dots'),
    });
    document.addEventListener('turbolinks:before-cache', () => $slidesChannels.slick('unslick'))
}

function initSliderProduct() {
    const $sliderProduct = $('.slider-product');
    if (!$sliderProduct.length) {
        return
    }
    $sliderProduct.slick({
        infinite: true,
        autoplay: false,
        autoplaySpeed: 5000,
        arrows: false,
        fade: true,
        dots: true,
        // appendDots: $('.slider-product-dots')
    });

    document.addEventListener('turbolinks:before-cache', () => $sliderProduct.slick('unslick'))
}

function initSliders() {
    initSliderMain();
    initSliderArticles();
    initSliderTrust();
    initSliderProduct();
}

export default initSliders
