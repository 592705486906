export default function () {
    const calculator = document.querySelector('.calculations__product__wrap');

    if (!calculator) return;

    const divModel = $('.js-data-model');
    const divSection = $('.js-data-section');

    $($('.products__card')[0]).addClass('active')


    $('.products__card').on('click', function (e) {
        if (!$(this).hasClass('active')) {
            $('.products__card').removeClass('active');
            $(this).addClass('active');
            divModel.html($(this).attr('data-name'))
            divSection.html($(this).attr('data-section') + 'шт.')
        }
    });



}
