import '../stylesheets/application.css'
import '../javascripts/application'



try {
    const images = require.context('../images', true, /\.(jpg|jpeg|png|svg|ico|woff)$/);
    const imagePath = (name) => images(name, true);
} catch (e) {
    console.log(e)
}

import "controllers/desktop"
