export default function () {
    const button = $('.btn__list__nav');
    const list = $('.list__block');
    button.on('click', function(e) {
        $(this).toggleClass("active");
        $(this).parent().toggleClass("active");
        e.preventDefault();
    });
    const button_top = $('.top__list__btn');
    const top_list = $('.top__list__block');


    top_list.find('li').each(function() {
        if ($(this).hasClass('active')){
            // top_list.toggleClass("active");
             top_list.parent().toggleClass("active");
        }

    });

    button_top.on('click', function(e) {
        $(this).toggleClass("active");
        $(this).parent().removeClass("active");
        $(this).parent().toggleClass("active");
        top_list.toggleClass("active");
        e.preventDefault();
    });

}
