export default function () {
    const calculator = document.querySelector('.js-calculation-form');
    const nextBtn = $('.js-step-next')

    if (!calculator) return;
    let currentTab = 0;
    showTab(currentTab);

    function showTab(n) {
        let x = document.getElementsByClassName("js-step");
        x[n].style.display = "block";
    }

    function nextPrev(n) {
        let x = document.getElementsByClassName("js-step");
        x[currentTab].style.display = "none";
        currentTab = currentTab + n;
        showTab(currentTab);
    }


    nextBtn.on('click', function (e) {
        nextPrev(1)
    });

}
