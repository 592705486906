export default function () {
    const menu = $('.catalog__menu');
    const elem = $('.catalog__menu__element');

    if (!menu.hasClass('product')) {
        elem.hover(
            function () {
                if (elem.parent().find('.active')) {
                    if (!$(this).hasClass('active')) {
                        elem.parent().find('.active').css("background-color", "var(--color-black-light)");
                        elem.parent().find('.active .catalog__menu__link').css("border-color", "var(--color-gold)");
                        elem.parent().find('.active .catalog__menu__link').css("color", "var(--color-gold)");
                    }
                }
            },
            function () {
                elem.parent().find('.active').removeAttr('style');
                elem.parent().find('.active .catalog__menu__link').removeAttr('style');
            }
        );
    }
}
