import {Controller} from "stimulus"


export default class extends Controller {
    static targets = ["menu", "logo"]

    connect() {
        this.setupHeader();
    }

    menu(event) {
        event.preventDefault()
        let menu = this.menuTarget
        let logo = this.logoTarget
        let button = event.target
        const CLASS_LOGO_OPEN = 'header__logo--closed';
        const CLASS_MENU_OPEN = 'header__menu--closed';
        const CLASS_BUTTON_OPEN = 'header__button--closed';
        logo.classList.toggle(CLASS_LOGO_OPEN);
        menu.classList.toggle(CLASS_MENU_OPEN);
        button.parentElement.classList.toggle(CLASS_BUTTON_OPEN);

    }

    setupHeader() {
        let header = document.getElementById("sticky-header");
        let sticky = header.offsetTop;
    }


}
