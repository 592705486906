import 'magnific-popup'

const popupInit = () => {
    $('.btn-popup').magnificPopup({
        type: 'inline',
        showCloseBtn: false,
        closeOnBgClick: false,
        callbacks: {
            beforeOpen() {
                $('body').addClass('mfp-active')
            },
            beforeClose() {
                $('body').removeClass('mfp-active')
            },
        },
    })

    $('.btn-popup-closing').magnificPopup({
        type: 'inline',
        showCloseBtn: false,
        closeOnBgClick: false,
        callbacks: {
            beforeOpen() {
                $('body').addClass('mfp-active')
            },
            beforeClose() {
                $('body').removeClass('mfp-active')
            },
        },
    })

    $('.close-btn').on("click", function () {
        $.magnificPopup.close();
    });



    // Кнопка закрытия поповера
    $('body').on('click', '.close-btn', () => {
        $.magnificPopup.close()
    })

    document.addEventListener('turbolinks:before-cache', () => {
        $.magnificPopup.close()
    })

}

export default popupInit
